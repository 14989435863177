video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.hero-container {
  height: 100vh;
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  object-fit: contain;
  padding-top: 45px;
  padding-bottom: 100px;
}

.title-container {
  display: flex;
  width: 100%;
  height: 35vw;
  max-width: 1920px;
  max-height: 400px;
  padding-top: 45px;
  justify-content: center;
  object-fit: contain;
  position: relative;
  top:0;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.title > h1 {
  font-size: 10vw;
  margin-bottom: 20px;
  margin-top: 10vh;
}

.title > h2 {
  font-size: 50px;
  margin-bottom: 50px;
}

.album-artwork {
  display: block;
  width: 80vw;
  height: auto;
  max-width: 50vh;
  max-height: 50vh;
  box-shadow: 0px 0px 20px 2px white;
  animation: glow 3s infinite;
  background-color: transparent;
  border: 0;
  border-radius: 7px;
}

@keyframes glow {
  50% {box-shadow: 0px 0px 50px 10px hsl(55, 90%, 92%, 0.5);}
}

@media screen and (min-width: 1920px) {
  .title > h1 {
    font-size: 195px;
  }
}

@media screen and (max-width: 768px) {
  .title > h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-height: 960px) {
  .title > h1 {
    font-size: calc(5vh + 2vw);
  }
  .title > h2 {
    font-size: calc(2vh + 1vw);
  }
}

@media screen and (max-width: 960px) {
  .title > h2 {
    font-size: 30px;
  }
}
