* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.page-wrap {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  background-color: rgb(48, 48, 48);
  object-fit: contain;
  overflow: hidden;
}

.info-page {
  display: flex;
  width: 100%;
  max-width: 1920px;
  flex-direction: column;
  align-self: center;
  justify-content: baseline;
  justify-self: center;
  align-items: center;
  align-content: center;
  color: rgb(238, 238, 238);
  background-color: rgb(48, 48, 48);
  box-shadow: 0 6px 300px rgba(56, 159, 255, 0.753);
  object-fit: contain;
  position: relative;
  padding-bottom: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.info-body {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  max-width: 1080px;
  height: 100%;
  align-self: center;
  box-shadow: 0 6px 300px rgba(192, 192, 192, 0.801);
  z-index: 1;
}

.info-body > h1 {
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.info-body > p {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.video-container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.video-thumbnail {
  width: 20%;
  font-size: 1rem;
  border-radius: 5px;
}

.mf-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.listen-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.listen-devider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1px;
  background-color:rgb(196, 194, 194);
  box-shadow: 0 0px 20px rgba(252, 255, 56, 0.753);
  margin-top: 10px;
  margin-bottom: 10px;
}

.listen-box {
  position: relative;
  width: 50%;
}

.listen-list {
  list-style: none;
}

.bandcamp-listen-small {
  border: 0;
  width: 60vw;
  height: 60vw;
  border-radius: 5px;

}

.bandcamp-listen-full {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .listen-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .listen-box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  font-size: 20px;
}

.form-container .paragraph {
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 20px;
}

.form-container .paragraph .message-none {
  display: none;
}

.form-container .paragraph > p {
  font-size: 20px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-container .form {
  padding-bottom: 20px;
}

.form-container .form .form-control {
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  background-color: gray;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.form-container .form .form-control::placeholder {
  color: #dbdbdb;
}

.form-container .form .btn {
  display: flex;
  width: 50%;
  color: #dbdbdb;
  border-radius: 2px;
  padding: 0.5rem 0.8rem;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background-color: gray;
  border: 1px solid #9E9E9E;
}

.form-container .form .btn:hover {
  color: rgb(88, 88, 88);
  transition: 1s;
  background-color: rgb(209, 209, 209);
}

.shows-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shows-container > h1 {
  font-size: 30px;
}

.lzy-img {
  object-fit: fill;
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.img-container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
