.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .video {
    object-fit: cover;
    width: auto;
    height: 100%;
  }

.video-thumb {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 400ms ease 0ms;
  }
  .tiny {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
  }