.footer-container {
  background-color: #242424;
  padding: 0.5rem 0 0.5rem 0;
  display: flex;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.creds {
  position: absolute;
  right: 0;
  padding-right: 20px;
  font-size: 0.5rem;
  color: #333;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  max-width: 1000px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.website-rights {
  color: #fff;
}

@media screen and (max-width: 820px) {
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
}
