.cards {
  padding: 4rem;
  background: #fff;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1080px;
  width: 80%;
}

.cards__items {
  align-items: center;
  margin-bottom: 20px;
}

.cards__item {
  display: flex;
  flex: 1;
  max-width: 400px;
  margin: auto;
  border-radius: 5px;
  background-color: rgb(82, 82, 82);
  box-sizing: border-box;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__text {
  color: #ebebeb;
  font-size: 17px;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 960px) {
  .cards__items {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 960px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
